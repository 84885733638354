import React, { useEffect, useState } from "react"
import AuthContent from "../gatsby-theme-wild-child/components/auth/AuthContent"
import Section from "gatsby-theme-wild-child/src/components/Section"
import PresetSelect from "../gatsby-theme-wild-child/components/prompt/PresetSelect"
import { AppContext } from "../gatsby-theme-wild-child/context/ContextProvider"

import {
  Box,
  HStack,
  Textarea,
  FormControl,
  FormErrorMessage,
  Select,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  Wrap,
  WrapItem,
  Checkbox,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react"

import axios from "axios"

function Prompt() {
  const { selectedPrompt, setSelectedPrompt } =
    React.useContext(AppContext)

    const [errors, setErrors] = useState({})
  const [currentTag, setCurrentTag] = useState("")
  const [namespaceListing, setNamespaceListing] = useState([])

  useEffect(() => {

    const handleListingNamespace = () => {
      axios
        .get(
          `${process.env.GATSBY_API_BASE_URL}/namespaces/list`,
          {}
        )
        .then((response: any) => {
          const items = response?.data.response.map(namespace => ({
            ...namespace,
            value: namespace.code,
            label: namespace.title,
          }))
          setNamespaceListing(items)
        })
        .catch(error => {
          console.error(error, "nameSpace error")
        })
    }

    handleListingNamespace()
  }, [])

  function handleChange(event) {
    const fieldName = event.target.name
    const fieldValue = event.target.value

    setSelectedPrompt(selectedPrompt => ({
      ...selectedPrompt,
      [fieldName]: fieldValue,
    }))
  }

  const handleCheckBoxChange = event => {
    const name = event.target.name
    const value = event.target.checked

    handleChange({
      target: {
        name,
        value,
      },
    })
  }

  const handleBlur = event => {
    const fieldName = event.target.name
    const fieldValue = event.target.value
    const prevErrors = { ...errors }

    if (!fieldValue) {
      prevErrors[fieldName] = "This field is required"
    } else {
      prevErrors[fieldName] = ""
    }

    let newErrors = {}
    for (const key in prevErrors) {
      if (prevErrors[key] !== "") {
        newErrors[key] = prevErrors[key]
      }
    }
    setErrors(newErrors)
  }

  const handleTagInputChange = event => {
    setCurrentTag(event.target.value)
  }

  const handleTagInputKeyDown = event => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault()
      addTag(event)
    }
  }

  const addTag = event => {
    if (currentTag.trim() !== "") {
      const tags = currentTag.split(",")
      const value = [...(selectedPrompt[event.target.name] || []), ...tags]

      const uniqueSet = new Set(value)
      const uniqueValues = Array.from(uniqueSet)

      handleChange({
        target: {
          name: event.target.name,
          value: uniqueValues,
        },
      })
      setCurrentTag("")
    }
  }

  const removeTag = (name, tag) => {
    const value = selectedPrompt[name].filter(t => t !== tag)
    handleChange({
      target: {
        name,
        value,
      },
    })
  }

  return (
    <AuthContent>
      <Section pt={8} bg="white">
        <PresetSelect />
        <HStack w="full" align="flex-start" spacing={12}>
          <Box flex="4">
            <FormControl
              id="namespace"
              isRequired
              mt={4}
              isInvalid={errors["namespace"]}
            >
              <Select
                iconColor="gray.200"
                name="namespace"
                borderWidth="1px"
                borderColor="gray.50"
                borderRadius="md"
                size={"md"}
                bg={selectedPrompt?.namespace ? "white" : "gray.50"}
                value={selectedPrompt?.namespace}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Select Namespace</option>
                {namespaceListing.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors["namespace"]}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="prompt"
              isRequired
              mt={4}
              isInvalid={errors["prompt"]}
            >
              <Textarea
                w="full"
                py={8}
                px={10}
                minH="700px"
                h="auto"
                name="prompt"
                isInvalid={errors["prompt"]}
                placeholder="Enter your prompt here"
                size="md"
                variant="outline"
                borderColor="gray.100"
                bg="gray.50"
                onBlur={handleBlur}
                onChange={handleChange}
                value={selectedPrompt?.prompt}
              />

              <FormErrorMessage>{errors["prompt"]}</FormErrorMessage>
            </FormControl>
          </Box>

          <VStack flex="1">
            <HStack w="full" align="flex-start" pt={4}>
              <FormControl>
                <Checkbox
                  name="isSelected"
                  isChecked={selectedPrompt?.isSelected}
                  colorScheme="red"
                  onChange={handleCheckBoxChange}
                >
                  Mark as current
                </Checkbox>
              </FormControl>
            </HStack>

            <VStack w="full" align="flex-start">
              <HStack w="full" py={2} justify="space-between">
                <Text
                  fontSize="13px"
                  width="100px"
                  color="dark.full"
                  fontWeight="semibold"
                >
                  Temperature
                </Text>
                <Box>
                  <NumberInput
                    rounded="sm"
                    borderColor="transparent"
                    fontSize="12px"
                    size="xs"
                    w={16}
                    color="dark.full"
                    fontWeight="semibold"
                    value={2}
                  >
                    <NumberInputField pr="0" h="30px" />
                  </NumberInput>
                </Box>
              </HStack>
              <HStack spacing={6} w="full">
                <Slider
                  aria-label="slider-ex-1"
                  min={0}
                  max={1}
                  step={0.01}
                  name="settings.temperature"
                >
                  <SliderTrack>
                    <SliderFilledTrack bg="red.full" />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </HStack>
            </VStack>

            <VStack w="full" align="flex-start">
              <HStack py={2} w="full" justify="space-between">
                <Text
                  fontSize="13px"
                  width="100px"
                  color="dark.full"
                  fontWeight="semibold"
                >
                  Max Length
                </Text>
                <Box>
                  <NumberInput
                    rounded="sm"
                    borderColor="transparent"
                    fontSize="12px"
                    size="xs"
                    w={16}
                    color="dark.full"
                    fontWeight="semibold"
                    value={200}
                  >
                    <NumberInputField pr="0" h="30px" />
                  </NumberInput>
                </Box>
              </HStack>
              <HStack spacing={6} w="full">
                <Slider
                  aria-label="slider-ex-1"
                  name="settings.max_length"
                  min={1}
                  max={4000}
                  step={1}
                >
                  <SliderTrack>
                    <SliderFilledTrack bg="red.full" />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </HStack>
            </VStack>

            <HStack w="full" align="flex-start" pt={4}>
              <Checkbox defaultChecked colorScheme="red">
                Use references
              </Checkbox>
            </HStack>

            <HStack w="full" align="flex-start" pt={2}>
              <Checkbox defaultChecked colorScheme="red">
                Use GTP-4
              </Checkbox>
            </HStack>

            <VStack w="full" align="flex-start">
              <HStack w="full" align="flex-start" pt={2}>
                <Input
                  value={currentTag}
                  size={"md"}
                  name="businessSetupKeywords"
                  bg={currentTag ? "white" : "gray.50"}
                  borderWidth="1px"
                  borderColor="gray.50"
                  borderRadius="md"
                  placeholder="Business setup keywords"
                  onChange={handleTagInputChange}
                  onKeyDown={handleTagInputKeyDown}
                />
              </HStack>

              <HStack w="full" align="flex-start" pt={2}>
                <Wrap h="200px" overflowY="auto">
                  {selectedPrompt?.businessSetupKeywords !== undefined &&
                    selectedPrompt?.businessSetupKeywords !== null &&
                    selectedPrompt?.businessSetupKeywords.map(tag => (
                      <WrapItem key={tag}>
                        <Tag
                          size="md"
                          borderRadius="full"
                          variant="subtle"
                          colorScheme="teal"
                        >
                          <TagLabel>{tag}</TagLabel>
                          <TagCloseButton
                            onClick={() =>
                              removeTag("businessSetupKeywords", tag)
                            }
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                </Wrap>
              </HStack>
            </VStack>

            <VStack w="full" align="flex-start">
              <HStack w="full" align="flex-start" pt={2}>
                <Input
                  value={currentTag}
                  size={"md"}
                  name="pricingKeywords"
                  bg={currentTag ? "white" : "gray.50"}
                  borderWidth="1px"
                  borderColor="gray.50"
                  borderRadius="md"
                  placeholder="Pricing keywords"
                  onChange={handleTagInputChange}
                  onKeyDown={handleTagInputKeyDown}
                />
              </HStack>

              <HStack w="full" align="flex-start" pt={2}>
                <Wrap h="200px" overflowY="auto">
                  {selectedPrompt?.pricingKeywords !== undefined &&
                    selectedPrompt?.pricingKeywords !== null &&
                    selectedPrompt?.pricingKeywords.map(tag => (
                      <WrapItem key={tag}>
                        <Tag
                          size="md"
                          borderRadius="full"
                          variant="subtle"
                          colorScheme="teal"
                        >
                          <TagLabel>{tag}</TagLabel>
                          <TagCloseButton
                            onClick={() => removeTag("pricingKeywords", tag)}
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                </Wrap>
              </HStack>
            </VStack>
          </VStack>
        </HStack>
      </Section>
    </AuthContent>
  )
}

export default Prompt

export const Head = () => <title>TaxGPT by Virtuzone | Variables</title>
